import '../styles/index.scss';
import $ from 'jquery';
import 'bootstrap';
import validate from 'jquery-validation';

$(document).ready(function () {
    //scroll
    var btn = $('#scroll');

    btn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, '300');
    });

    // faq modal

    $('#faqOpen').on('click', function (e) {
        $('.popup__wrapper').addClass('js-active');
    });

    $('.popup__wrapper').on('click', function (e) {
        if (e.target !== this)
            return;
        $(this).removeClass('js-active');
        localStorage.setItem('Modal_shown', 'shown');
    });

    $('.popup__close').on('click', function (e) {
        $('.popup__wrapper').trigger('click');
    });

    // form validation

    $('#form').validate({
        rules: {
            name: {
                required: true
            },
            email: {
                email: true,
                required: true
            },
            message: {
                required: true
            }
        },
        messages: {
            name: 'Imię jest wymagane',
            email: {
                required: 'E-mail jest wymagany',
                email: 'Nieprawidłowy adres e-mail'
            },
            message: 'Wiadomość nie może być pusta'
        },
        showErrors: function (errorMap, errorList) {
            this.defaultShowErrors();
        },
        errorPlacement: function (error, element) {
            error.insertAfter(element);
        }
    });

    // google maps

    var mapa;

    function mapaStart() {
        var wspolrzedne = new google.maps.LatLng(52.1753299, 21.0277128);
        var opcjeMapy = {
            zoom: 17,
            scrollwheel: false,
            center: wspolrzedne,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [{
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#444444"
                    }]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [{
                        "color": "#f7f7f7"
                    }]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [{
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [{
                            "color": "#f7f7f7"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ]
        };

        mapa = new google.maps.Map(document.getElementById("maps-contact"), opcjeMapy);


        var image = {
            url: './public/images/marker.png',
            // This marker is 20 pixels wide by 32 pixels high.
            // size: new google.maps.Size(330, 100),
            // The origin for this image is (0, 0).
            // origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(155, 15)
        };

        function dodajMarker(lat, lng, txt) {
            var opcjeMarkera = {
                position: new google.maps.LatLng(lat, lng),
                icon: image,
                map: mapa,
            };
            var marker = new google.maps.Marker(opcjeMarkera);
            marker.txt = txt;
        }

        var marker = dodajMarker(52.1753299, 21.0277128, '');

    }

    document.body.onload = mapaStart();

});